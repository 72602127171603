<template>
  <PageHero
    :src="image"
    :alt="imageAltText"
    :headline="headline"
    :headline-color="headline_color"
    :claim="claim"
    :claim-color="claim_color"
    :link-url="anchor_link"
    :link-text="anchor_text"
    :links="links"
    :desktop-srcset="desktopSrcset"
    :mobile-srcset="mobileSrcset"
  />
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      claim() {
        return this.getValue("claim")
      },
      claim_color() {
        return this.getValue("claim_color")
      },
      image() {
        return this.getValue("image")
      },
      imageAltText() {
        return this.getIngredient("image")?.altText
      },
      desktopSrcset() {
        return this.getIngredient("image")?.srcset
      },
      mobileSrcset() {
        return this.getIngredient("mobile_image")?.srcset
      },
      anchor_link() {
        return this.getIngredient("anchor_link")?.linkUrl
      },
      anchor_text() {
        return this.getValue("anchor_link")
      },
      headline() {
        return this.getValue("headline")
      },
      headline_color() {
        return this.getValue("headline_color")
      },
      links() {
        return this.element.nestedElements
      },
    },
  }
</script>
